/**
 * Plugin Template frontend js.
 *
 *  @package WordPress Plugin Template/JS
 */

jQuery(document).ready(function (e) {
	
if (document.querySelector(".svt--map-container-update")) {
  var averageX,
    averageY,
    $ = jQuery,
    toggle = $(".svt--map-container-update .svt--map-content .toggle"),
    dt = $(".svt--map-container-update .svt--map-content .toggle dt"),
    plzSearch = document.getElementById("postleitzahlensuche"),
    searchError = document.getElementById("search--error"),
    plus = document.getElementById("plus"),
    minus = document.getElementById("minus"),
    reset = document.getElementById("reset"),
    karte = document.getElementById("svt--standorte-karte"),
    standortKarte = $(".svg--circle"),
    standortCircle = document.querySelectorAll(".svg--circle image"),
    standorte = document.querySelectorAll(".svg--circle"),
    kartenContainer = document.querySelector(".svt--map"),
    closeModal = $("#modal-close"),
    standortArray = [],
    xPosArray = [],
    yPosArray = [],
    brandschutzArray = document
      .querySelector("[data-filter='svt-brandschutz']")
      .children[1].querySelectorAll("[data-plz]"),
    brandsanierungArray = document
      .querySelector("[data-filter='svt-brandsanierung']")
      .children[1].querySelectorAll("[data-plz]"),
    companyGroup = $(".company-group"),
    modal = $("#modal"),
    modalTitle = document.getElementById("modal--title"),
    modalAddress = document.getElementById("modal--address");
  companyGroup.on("click", function (e) {
    var r = e.target.parentNode.parentNode.id;
    if ((modal.addClass("active"), "svt-zentrale" === r))
      (modalTitle.innerHTML = "Zentrale"),
        (modalAddress.innerHTML =
          'svt Holding GmbH<br>Glüsinger Straße 86 <br>21217 Seevetal<br><a href="tel:+49 (0) 4105 40 90-0">+49 (0) 4105 40 90-0</a><br><a href="mailto:info@svt.de">E-Mail senden</a></br>');
    else if ("kuhn" === r) {
      for (let i = 0; i < standorteArray.length; i++) {
        if (standorteArray[i].class === "kuhn") {
          console.log(standorteArray[i]);
          modalTitle.innerHTML = "";
          modalAddress.innerHTML = "";

          for (let j = 0; j < standorteArray[i].content.length; j++) {
            modalAddress.innerHTML +=
              "<h3>" +
              standorteArray[i].content[j][0] +
              "</h3>" +
              standorteArray[i].content[j][1] +
              "<br>" +
              standorteArray[i].content[j][2] +
              " " +
              standorteArray[i].content[j][3] +
              "<br><a href='tel:'" +
              standorteArray[i].content[j][4] +
              "'>" +
              standorteArray[i].content[j][4] +
              "</a><br><a href='mailto:" +
              standorteArray[i].content[j][7] +
              "'>" +
              standorteArray[i].content[j][7] +
              "</a><br></br>";
          }
        }
      }
      modalTitle.innerHTML = "";
    } else {
      modal.addClass("active");
      var a = e.target.parentNode.id,
        n = {};
      standorteArray.forEach(function (e) {
        var r = e.class,
          a = e.name,
          t = {};
        e.content.forEach(function (e) {
          t[e[2]] = e;
        }),
          (n[r] = t),
          (n[r].firma = a);
      });
      var t = n[r],
        s = t[a];
      (modalTitle.innerHTML = t.firma),
        (modalAddress.innerHTML =
          "  " +
          s[0] +
          "<br>\n                                    " +
          s[1] +
          "<br>\n                                    " +
          s[2] +
          " " +
          s[3] +
          '<br>\n                                    <a href="tel:' +
          s[4] +
          '">' +
          s[4] +
          "</a><br>\n                                    " +
          (s[5]
            ? '<a href="tel:' + s[5] + '">24h Notruf: ' + s[5] + "</a><br>"
            : "") +
          "\n                                    " +
          (s[7] ? '<a href="mailto:' + s[7] + '">' + s[7] + "</a><br>" : "") +
          "\n                                    ");
    }
  }),
    dt.on("click", function () {
      modal.removeClass("active"),
        $(this).parent().hasClass("active")
          ? $(this).parent().removeClass("active")
          : (toggle.removeClass("active"), $(this).parent().addClass("active"));
      var e = $(this).parent().attr("data-filter");
      $(this).parent().hasClass("active")
        ? ($(".company-group").hide(), $("#" + e).show())
        : $(".company-group").show();
    }),
    closeModal.on("click", function () {
      modal.removeClass("active");
    }),
    window.addEventListener("keyup", function (e) {
      ("Escape" !== e.key && "Esc" !== e.key) || modal.removeClass("active");
    });
  var searchRegions = function (l) {
      standorteArray.forEach(function (e) {
        e.class === l &&
          ($("[data-filter='" + l + "'] dd p").hide(),
          e.content.map(function (o) {
            var e = o[6];
            e &&
              e.map(function (e) {
                var r, a, t;
                if (
                  ((r = e[0]),
                  (a = e[1]),
                  (t = parseInt(plzSearch.sucheplz.value)),
                  t <= a && r <= t)
                ) {
                  $(
                    "[data-filter='" + l + "'] [data-plz='" + o[2] + "']"
                  ).show(),
                    $("#" + l + " #" + o[2]).show();
                  var n = $("#" + l + " #" + o[2]).children()[1].cx.baseVal
                      .value,
                    s = $("#" + l + " #" + o[2]).children()[1].cy.baseVal.value;
                  xPosArray.push(n),
                    yPosArray.push(s),
                    standortArray.push(document.getElementById(o[2])),
                    (averageX =
                      xPosArray.reduce(function (e, r) {
                        return e + r;
                      }, 0) / xPosArray.length),
                    (averageY =
                      yPosArray.reduce(function (e, r) {
                        return e + r;
                      }, 0) / yPosArray.length),
                    centerMapToActiveCircle(averageX, averageY),
                    (searchError.innerHTML = "");
                }
              });
          }));
      });
    },
    suchradius = 1500;
  plzSearch.addEventListener("submit", function (e) {
    e.preventDefault(),
      (xPosArray = []),
      (yPosArray = []),
      modal.removeClass("active");
    var t = parseInt(plzSearch.sucheplz.value);
    isNaN(t) || 5 !== plzSearch.sucheplz.value.length
      ? ("" === plzSearch.sucheplz.value
          ? (standortKarte.show(), (searchError.innerHTML = ""))
          : (searchError.innerHTML =
              "Bitte eine gültige Postleitzahl eingeben!"),
        resetMap())
      : ((searchError.innerHTML = ""),
        standortKarte.hide(),
        standorte.forEach(function (e) {
          if (
            Math.abs(t - e.id) > suchradius ||
            "svt-brandschutz" === e.parentNode.id ||
            "svt-brandsanierung" === e.parentNode.id
          )
            (e.style.display = "none"),
              $("#svt-zentrale g").show(),
              $("#bio g").show(),
              $("#aik g").show(),
              $("#kuhn g").show(),
              $("#flamro g").show();
          else {
            e.style.display = "block";
            var r = e.children[1].cx.baseVal.value,
              a = e.children[1].cy.baseVal.value;
            xPosArray.push(r), yPosArray.push(a), standortArray.push(e);
          }
        }),
        brandschutzArray.forEach(function (e) {
          parseInt(e.dataset.plz);
          searchRegions("svt-brandschutz");
        }),
        brandsanierungArray.forEach(function (e) {
          parseInt(e.dataset.plz);
          searchRegions("svt-brandsanierung");
        }),
        0 < standortArray.length
          ? ((searchError.innerHTML = ""),
            (averageX =
              xPosArray.reduce(function (e, r) {
                return e + r;
              }, 0) / xPosArray.length),
            (averageY =
              yPosArray.reduce(function (e, r) {
                return e + r;
              }, 0) / yPosArray.length),
            centerMapToActiveCircle(averageX, averageY))
          : ((searchError.innerHTML = "Leider kein Ergebnis im Ihrem Umkreis"),
            resetMap()));
  });
  var resetMap = function () {
      (karte.style.left = 0),
        (karte.style.top = 0),
        (karte.style.transform = "scale(1)"),
        standortCircle.forEach(function (e) {
          e.setAttribute("width", 93);
        }),
        $(".toggle dd p").show(),
        (scale = 1);
    },
    scale = 1,
    kreisZoom = function () {
      var e = $("#svt--standorte-karte").css("transform").split("(")[1],
        r = (e = (e = e.split(")")[0]).split(","))[0],
        a = e[1],
        t = Math.sqrt(r * r + a * a);
      standortCircle.forEach(function (e) {
        1 < scale && scale < 3.4 && e.setAttribute("width", 93 - 15 * t),
          scale < 1.1 && e.setAttribute("width", 93);
      });
    };
  plus.addEventListener("click", function () {
    scale < 3.4 &&
      ((scale += 0.4), (karte.style.transform = "scale(" + scale + ")")),
      kreisZoom();
  }),
    minus.addEventListener("click", function () {
      1 < scale &&
        ((scale -= 0.4), (karte.style.transform = "scale(" + scale + ")")),
        kreisZoom();
    }),
    reset.addEventListener("click", function () {
      resetMap(),
        toggle.removeClass("active"),
        standortKarte.show(),
        $(".company-group").show(),
        (searchError.innerHTML = ""),
        plzSearch.reset(),
        standortCircle.forEach(function (e) {
          var r;
          (r = "zentrale" === e.id ? 14 : 8.5), e.setAttribute("r", r);
        });
    });
  var centerMapToActiveCircle = function (e, r) {
    var a = (280 * e) / 572.7 - 140,
      t = (320 * r) / 774.6 - 160;
    (karte.style.transform = "scale(3.4)"),
      (karte.style.left = -a + "%"),
      (karte.style.top = -t + "%"),
      (scale = 3.4),
      standortCircle.forEach(function (e) {
        e.setAttribute("width", 48);
      });
  };
  $("#svt--standorte-karte").draggable({});
}

});
